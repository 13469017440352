import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Img/1.png'
import { useState } from 'react';
import api from "../Api"
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../Constants'


function Registrati() {

    const [utente, setUtente] = useState("Fan");
    const [ig, setIg] = useState("No");
    const [tiktok, setTiktok] = useState("No");
    const [clickable, setClickable] = useState("No");
    const [open, setOpen] = useState("false");
    const [loading, setLoading] = useState("false")
    const [errorMessage, setErrorMessage] = useState(""); // Stato per gestire il messaggio di errore
    const navigate = useNavigate()

    const [terminiAccettati, setTerminiAccettati] = useState(false);
    const [clausoleAccettate, setClausoleAccettate] = useState(false);

    const [etaErrore, setEtaErrore] = useState(""); // Stato per il messaggio di errore sull'età


    const handleTerminiChange = (e) => {
        setTerminiAccettati(e.target.checked);
    };
    
    const handleClausoleChange = (e) => {
        setClausoleAccettate(e.target.checked);
    };
    
   
    const [user, setUser] = useState({
        username: "",
        password: "",
        email: "",
        profile: {
            type: utente, // "creator" o "fan"
            nome: "",
            usernameig: "",
            usernametiktok: "",
            dob: {
                giorno: "",
                mese: "",
                anno: "",
            },
            data_nascita : "",
        },
    });
    
    const calcolaEta = () => {
        const { giorno, mese, anno } = user.profile.dob;
        if (!giorno || !mese || !anno) return null; // Se la data non è completa, non fare il calcolo
    
        const dataNascita = new Date(anno, mese - 1, giorno);
        const oggi = new Date();
        const eta = oggi.getFullYear() - dataNascita.getFullYear();
    
        // Correggi il calcolo dell'età se il compleanno non è ancora passato quest'anno
        const meseCorrente = oggi.getMonth();
        const giornoCorrente = oggi.getDate();
        if (meseCorrente < dataNascita.getMonth() || (meseCorrente === dataNascita.getMonth() && giornoCorrente < dataNascita.getDate())) {
            return eta - 1;
        }
    
        return eta;
    };
    

    const handleForm = async (e) => {
        e.preventDefault();
        setLoading("true");
        setErrorMessage(""); // Resetta il messaggio di errore
        setEtaErrore(""); // Resetta il messaggio di errore sull'età

        const eta = calcolaEta();

        // Se l'età è inferiore a 14, mostra un messaggio di errore e blocca l'invio del form
        if (eta !== null && eta < 14) {
            setEtaErrore("E' necessario avere almeno 14 anni per potersi registrare.");
            setLoading("false");
            return;
        }


        const { giorno, mese, anno } = user.profile.dob;
    
        // Controlla se tutti i campi della data di nascita sono stati riempiti
        if (!giorno || !mese || !anno) {
            setErrorMessage("Compila tutti i campi della data di nascita.");
            return;
        }
    
        // Calcola la data di nascita e verifica l'età
        const birthDate = new Date(anno, mese - 1, giorno); // JavaScript usa mesi 0-based
        const formattedDate = birthDate.toISOString().split("T")[0]; // Ottieni formato YYYY-MM-DD
    
        // Costruisci l'oggetto da inviare al backend con la data corretta
        const updatedUser = {
            ...user,
            profile: {
                ...user.profile,
                data_nascita: formattedDate, // Formato YYYY-MM-DD
            },
        };
    
        try {
            // Invia direttamente l'oggetto aggiornato
            const res = await api.post("sparklingApi/user/register/", updatedUser);
            navigate("/home/");
        } catch (error) {
            if (error.response && error.response.data) {
                const errors = error.response.data;
                if (errors.username) {
                    setErrorMessage(errors.username[0]); // Messaggio specifico per lo username
                } else if (errors.email) {
                    setErrorMessage(errors.email[0]); // Messaggio specifico per l'email
                } else if (errors.password) {
                    setErrorMessage(errors.password[0]); // Messaggio specifico per la password (se necessario)
                } else {
                    setErrorMessage("Errore durante la registrazione. Riprova.");
                }
            } else {
                setErrorMessage("Errore di connessione. Riprova.");
            }
        } finally {
            setLoading("false");
        }
    };
    
      
      
      
    
   

      const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (["giorno", "mese", "anno"].includes(name)) {
            setUser({
                ...user,
                profile: {
                    ...user.profile,
                    dob: {
                        ...user.profile.dob,
                        [name]: value,
                    },
                },
            });
        } else if (["type", "nome", "usernameig", "usernametiktok"].includes(name)) {
            setUser({
                ...user,
                profile: {
                    ...user.profile,
                    [name]: value,
                },
            });
        } else {
            setUser({
                ...user,
                [name]: value,
            });
        }
    };
    

    const changeFUser = () =>{
        setUtente("Fan")
        // setUser({...user, ["usertype"]: "Fan"})

        setUser({
            ...user,
            profile: {
                ...user.profile,
                ["type"]: "Fan"
            }
        });
        
    }

    const changeCUser = () =>{
        setUtente("Creator")
        setUser({
            ...user,
            profile: {
                ...user.profile,
                ["type"]: "Creator"
            }
        });
        
    }

    const changeIg = () =>{
        if (ig === "No") {
            setIg("Si")
        } else {
            setIg("No")
        }
    }

    const changeTiktok = () =>{
        if (tiktok === "No") {
            setTiktok("Si")
        } else {
            setTiktok("No")
        }
    }

    const isButtonEnabled = utente === "Fan" 
    ? terminiAccettati  // Se è un fan, basta accettare i termini
    : (terminiAccettati && clausoleAccettate); // Se è un creator, deve accettare entrambi


    const onClick = () => {
        if (open === "false") {
          setOpen("true")
        }
        if (open === "true") {
          setOpen("false")
        }  
        
      }

  return (
    <>
    

    <div className='min-h-screen bg-white'>
        
        <div className='grid grid-cols-12 min-h-[100px] mt-10'>
            <div className='sm:col-span-2 col-span-1'></div>

            <div className='sm:col-span-4 sm:block hidden'>
                <div className='mt-24 bg-slate-50 rounded-sm min-h-[200px] p-1'>
                    <Link to='/'>
                    <img src={Logo} className='max-h-[50px]  mt-10 '></img>
                    </Link>
                    { utente === "Creator" && 
                    <>
                    <ul className='list-disc list-inside mt-10 mx-4 font-poppinsLight text-slate-700 '>
                        <li className=''>
                            <b>Condividi la tua vita con i tuoi fan</b> <br/>
                            <p className='ml-6'>Dimostra il tuo affetto ai tuoi followers e lascia che diventino parte integrante del tuo percorso.</p>
                        </li>
                        <li className='mt-4'>
                            <b>Carica i contenuti per farti conoscere</b> <br/>
                            <p className='ml-6'>Aspetti di te mai visti, nuovi gossip e sfide quotidiane.</p>
                        </li>
                        <li className='mt-4 '>
                            <b>Monetizza il tuo lavoro</b> <br/>
                            <p className='ml-6'>Metti a frutto le tue potenzialità e crea un nuovo stream di entrate.</p>
                        </li>
                    </ul>
                    </>}

                    { utente === "Fan" && 
                    <>
                    <ul className='list-disc list-inside mt-10 mx-4 font-poppinsLight text-slate-700 '>
                        <li className=''>
                            <b>Sbircia nella vita dei Vip</b> <br/>
                            <p className='ml-6'>Sei curioso/a di sapere come vivono influencer e personaggi famosi? Scopri lati di loro che non hai mai visto.</p>
                        </li>
                        <li className='mt-4 '>
                            <b>Hanno voluto condividere con te</b> <br/>
                            <p className='ml-6'>Contenuti super esclusivi, come le chat e la galleria foto/video del loro cellulare, solo su Sparkling.</p>
                        </li>
                        <li className='mt-4'>
                            <b>Conosci il loro lato più intimo</b> <br/>
                            <p className='ml-6'>Crea un legame vero e profondo con i tuoi idoli, entra a far parte della loro vita.</p>
                        </li>
                        
                    </ul>
                    </>}


                    <div className='min-h-8'></div>

                    {open === "true" &&
                        <div className='bg-white shadow-md w-60 h-30 rounded-xl mt-4 p-2 text-slate-700 ml-10'> 
                            <div className='flex justify-end'>
                            <button className='font-poppinsBold' onClick={onClick} >X</button>
                            </div>
              
                            <p className='font-poppinsLight'> I contenuti al momento disponibili potrebbero non includere l'accesso alla galleria foto/video.</p>
              
                        </div>}
                        
                    
                </div>





            </div>
            
            
            <div className='sm:col-span-4 col-span-10'>
            <Link to='/'>
            <img src={Logo} className='max-h-[50px] sm:hidden block'></img>
            </Link>
            <div className='min-h-fit rounded-md shadow-2xl mt-6'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-1'></div>
                        <div className='col-span-10'>
                            <p className='font-poppinsBold text-xl sm:mt-10 mt-6 text-slate-700'>Crea il tuo account Sparkling</p>
                             
                            
                            <form onSubmit={handleForm}>
                            
                                <div className='flex justify-evenly text-md text-slate-700 mt-4 font-poppinsLight font-bold '>
                                    <div className='flex items-center p-2' >
                                        <input type='radio' name="check" id='creator' className='m-2 w-4 h-4 ' onClick={changeCUser}/>
                                        <label for='creator'>Creator</label>
                                    </div><br/>
                                    <div className='flex items-center p-2' >
                                        <input type='radio' name="check" id='fan' className='m-2 w-4 h-4' defaultChecked onClick={changeFUser}/>
                                        <label for='fan'>Fan </label>
                                    </div>
                                </div>
                                {utente === "Fan" && 
                                
                                <>
                                    <p className='font-poppinsLight mt-3 text-slate-500 '> Seleziona questo profilo se vuoi accedere ai contenuti dei tuoi creators preferiti</p>
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Nome completo</p>
                                    <input onChange={handleChange} type='text' name='nome' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Username</p>
                                    <input onChange={handleChange} type='text' name='username' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Email</p>
                                    <input onChange={handleChange} type='email' name='email' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Password</p>
                                    <input onChange={handleChange} type='password' name='password' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Data di nascita</p>
                                    <div className="flex items-center justify-around gap-1 mt-3">
                                        <select
                                            name="giorno"
                                            onChange={handleChange}
                                            className="text-center px-1 py-3 border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0"
                                            required
                                        >
                                            <option value="">Giorno</option>
                                            {[...Array(31).keys()].map((d) => (
                                                <option key={d + 1} value={d + 1}>
                                                    {d + 1}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            name="mese"
                                            onChange={handleChange}
                                            className=" text-center px-1 py-3 border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0"
                                            required
                                        >
                                            <option value="">Mese</option>
                                            {[
                                                "Gennaio",
                                                "Febbraio",
                                                "Marzo",
                                                "Aprile",
                                                "Maggio",
                                                "Giugno",
                                                "Luglio",
                                                "Agosto",
                                                "Settembre",
                                                "Ottobre",
                                                "Novembre",
                                                "Dicembre",
                                            ].map((m, idx) => (
                                                <option key={idx + 1} value={idx + 1}>
                                                    {m}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            name="anno"
                                            onChange={handleChange}
                                            className="text-center px-1 py-3 border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0"
                                            required
                                        >
                                            <option value="">Anno</option>
                                            {Array.from(
                                                { length: 100 },
                                                (_, i) => new Date().getFullYear() - i
                                            ).map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                </>
                                }

                                {utente === "Creator" &&

                                <>
                                    <p className='font-poppinsLight mt-3 text-slate-500 text-md'> Se sei un influencer o hai un discreto seguito sui social. La tua richiesta verrà sottoposta ad una valutazione interna.</p>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Sei attivo/a su:</p>
                                    

                                    <input type='checkbox' name='ig'className='h-4 w-4 mt-3 ' onClick={changeIg}></input>
                                    <label for='ig' className='font-poppinsLight ml-3  text-sm text-slate-700'>Instagram</label>

                                    
                                    
                                    { ig === "Si" && 
                                    
                                    <>
                                        
                                        <input onChange={handleChange} placeholder='@ ig' type='text' name='usernameig' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    </>

                                    } <br/>

                                    <input type='checkbox' name='tiktok'className='h-4 w-4 mt-3 ' onClick={changeTiktok}></input>
                                    <label for='tiktok' className='font-poppinsLight ml-3  text-sm text-slate-700  '>TikTok</label>

                                    
                                    
                                    { tiktok === "Si" && 
                                    
                                    <>
                                        
                                        <input onChange={handleChange} placeholder='@ TikTok' type='text' name='usernametiktok' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    </>

                                    }




                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Nome completo</p>
                                    <input onChange={handleChange} type='text' name='nome' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Username</p>
                                    <input onChange={handleChange} type='text' name='username' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Email</p>
                                    <input onChange={handleChange} type='email' name='email' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                    
                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Password</p>
                                    <input onChange={handleChange} type='password' name='password' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>

                                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Data di nascita</p>
                                    <div className="flex items-center justify-around gap-1 mt-3">
                                        <select
                                            name="giorno"
                                            onChange={handleChange}
                                            className="text-center px-1 py-3 border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0"
                                            required
                                        >
                                            <option value="">Giorno</option>
                                            {[...Array(31).keys()].map((d) => (
                                                <option key={d + 1} value={d + 1}>
                                                    {d + 1}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            name="mese"
                                            onChange={handleChange}
                                            className=" text-center px-1 py-3 border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0"
                                            required
                                        >
                                            <option value="">Mese</option>
                                            {[
                                                "Gennaio",
                                                "Febbraio",
                                                "Marzo",
                                                "Aprile",
                                                "Maggio",
                                                "Giugno",
                                                "Luglio",
                                                "Agosto",
                                                "Settembre",
                                                "Ottobre",
                                                "Novembre",
                                                "Dicembre",
                                            ].map((m, idx) => (
                                                <option key={idx + 1} value={idx + 1}>
                                                    {m}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            name="anno"
                                            onChange={handleChange}
                                            className="text-center px-1 py-3 border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0"
                                            required
                                        >
                                            <option value="">Anno</option>
                                            {Array.from(
                                                { length: 100 },
                                                (_, i) => new Date().getFullYear() - i
                                            ).map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    
                                    
                                </>
                                }
                                
                                

                                
                                <input type='checkbox' name='termini' className='h-4 w-4 mt-6 ' onClick={handleTerminiChange} ></input>
                                <label for='termini' className='mt-6 font-poppinsLight text-sm ml-3'>Iscrivendomi a Sparkling accetto i <Link to='/termini_e_condizioni' target="_blank" rel="noopener noreferrer"><b className='text-primary'>Termini e condizioni d'uso</b></Link> e di aver letto e compreso l' <Link to='/privacy' target="_blank" rel="noopener noreferrer"><b className='text-primary'>Informativa Privacy.</b></Link></label>
                                
                                {utente === "Creator" && 
                                    <>
                                    <br/>
                                    <input type='checkbox' name='clausole' className='h-4 w-4 mt-6 ' onClick={handleClausoleChange}></input>
                                    <label for='clausole' className='mt-6 font-poppinsLight text-sm ml-3'>Accetto espressamente le clausole 1.3, 4, 6, 9, 10 e 13.1 dei <Link to='/termini_e_condizioni' target="_blank" rel="noopener noreferrer"><b className='text-primary'>Termini e condizioni d'uso</b></Link></label>
                                    </>
                                }

                                <button 
                                    type="submit" 
                                    className={`mt-8 w-full px-4 py-2 rounded-3xl font-poppinsBold 
                                        ${isButtonEnabled ? "bg-primary text-white hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold" 
                                        : "opacity-45 bg-gray-400 text-white cursor-not-allowed"}`} 
                                    disabled={!isButtonEnabled}
                                >
                                    Registrati >
                                </button>
                                
                            </form>
                            {errorMessage && (
                                <p className="text-red-600 text-center font-poppinsLight mt-8">
                                    {errorMessage}
                                </p>
                            )}
                            {etaErrore && (
                                <p className="text-red-600 text-center font-poppinsLight mt-2">
                                    {etaErrore}
                                </p>
                            )}
                            
                            <div className='mt-8 sm:mb-10 mb-6 bg-slate-100 rounded-md min-h-[50px] flex items-center justify-center'>
                                <p className=' text-slate-500 font-poppinsLight'>Hai già un account? <b className='text-primary'><Link to='/login'>Accedi</Link></b></p>
                            </div>
                        </div>
                        <div className='sm:col-span-1'></div>

                    </div>
                    

                </div>

            </div>
            <div className='sm:col-span-2 col-span-1'></div>
        </div>
    </div>
    
    
    
    
    </>
  )
}

export default Registrati