import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../Api';
import Logo from '../Img/1.png';
import { Link } from 'react-router-dom';

const PasswordResetConfirm = () => {
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica che le password siano uguali
    if (newPassword !== confirmPassword) {
      setError('Le password non corrispondono.');
      return;
    }

    // Se le password corrispondono, invia la richiesta
    setError(''); // Resetta eventuali errori precedenti
    try {
      const response = await api.post(`/sparklingApi/reset-password/${uidb64}/${token}/`, { new_password: newPassword });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Errore durante il reset della password.');
    }
  };

  return (
    <>
      <div className='min-h-screen bg-white'>
        <div className='grid grid-cols-12 min-h-screen'>
          <div className='sm:col-span-4 col-span-1'></div>
          <div className='sm:col-span-4 col-span-10'>
            <Link to='/'>
              <img src={Logo} className='max-h-[50px] mt-10' alt="Logo" />
            </Link>
            <div className='min-h-fit rounded-md shadow-2xl mt-6'>
              <div className='grid grid-cols-12'>
                <div className='col-span-1'></div>
                <div className='col-span-10'>
                  <p className='font-poppinsBold text-xl sm:mt-10 mt-6 text-slate-700'>Reimposta la tua password</p>
                  <form onSubmit={handleSubmit}>
                    <p className='font-poppinsLight mt-10 font-bold text-sm text-slate-700'>Inserisci la tua nuova password:</p>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Nuova Password"
                      className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0'
                      required
                    />

                    <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Conferma la tua nuova password:</p>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Conferma Password"
                      className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0'
                      required
                    />

                    {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}
                    
                    <button
                      type='submit'
                      className='mt-8 mb-8 w-full bg-primary text-white font-poppinsBold px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold'
                    >
                      Reimposta Password >
                    </button>
                  </form>
                  <p className='text-center font-poppinsLight text-slate-700'>
                    {message && <p>{message}</p>}
                  </p>
                  <p className='text-center font-poppinsLight text-slate-700 mb-2'>
                    Torna al <Link to='/login' className='text-primary'>Login</Link>
                  </p>
                </div>
                <div className='col-span-1'></div>
              </div>
            </div>
          </div>
          <div className='sm:col-span-4 col-span-1'></div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetConfirm;
