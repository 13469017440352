import React, { useState } from 'react';
import api from '../Api'
import Logo from '../Img/1.png'
import { Link, useNavigate } from 'react-router-dom'

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/sparklingApi/request-reset-password/', { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Errore nel reset della password.');
    }
  };

  return (

    <>
    
    <div className='min-h-screen bg-white'>

        <div className='grid grid-cols-12 min-h-screen '>
            <div className='sm:col-span-4 col-span-1'></div>
            <div className='sm:col-span-4 col-span-10'>
            <Link to='/'>
                <img src={Logo} className='max-h-[50px] mt-10'></img>
            </Link>
                <div className='min-h-fit rounded-md shadow-2xl mt-6'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-1'></div>
                        <div className='col-span-10'>
                            <p className='font-poppinsBold text-xl sm:mt-10 mt-6 text-slate-700'>Reimposta la tua password</p>
                            <form onSubmit={handleSubmit}>
                                <p className='font-poppinsLight mt-10 font-bold text-sm text-slate-700'>Inserisci la tua e-mail associata al profilo:</p>
                                <input 
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Inserisci la tua email"
                                
                                
                                className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' 
                                required/>
                                
                                
                                <button type='submit' className='mt-8 mb-8 w-full bg-primary text-white font-poppinsBold px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold'> Invia E-mail > </button>
                            </form>
                            <p className='text-center font-poppinsLight text-slate-700'>
                                {message && <p>{message}</p>}
                            </p>
                            
                            
                            
                        </div>
                        <div className='col-span-1'></div>

                    </div>
                    

                </div>


            </div>
            <div className='sm:col-span-4 col-span-1'></div>
        </div>

    </div>
        
    
    
    
    
    
    </>
    
  );
};

export default RequestPasswordReset;
