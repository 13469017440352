import React from 'react'

function Privacy() {
  return (
    <div className='min-h-full'>
        <div className='grid col-span-12'>
            <div className='grid-cols-1'></div>
            <div className='grid-cols-10 '>
                <h1 className='font-poppinsLight text-lg text-slate-900 mt-6 text-center font-bold'>
                    Informativa sul trattamento dei dati personali degli utenti
                    <br/>
                </h1>
                <p className='font-poppinsLight text-base text-slate-700 mt-6 text-center'> 
                    Aggiornata al 14.02.2025
                </p>
                <p className='font-poppinsLight text-sm text-slate-700 mt-6 p-4 leading-loose'> 

                <b>1.	Titolare del trattamento</b> <br/><br/>
                    Ti informiamo, ai sensi del Regolamento (UE) 2016/679 (“GDPR”), che i tuoi dati personali saranno trattati da  Mearas S.r.l. Socio Unico, con sede in Via Passarella 4, 20122, Milano, C.F. e P. IVA 13996260967, in qualità di titolare del trattamento (“Società” o “Titolare”), raggiungibile all’indirizzo e-mail info.sparklingapp@gmail.com. <br/>
                    In particolare, la presente informativa ti è fornita in relazione ai dati che la Società raccoglie quando navighi sul sito www.sparklingapp.it (“Sito Web”) e/o utilizzi la piattaforma Sparkling e le sue funzionalità (“Sparkling”). <br/>
                    Sparkling è la piattaforma, offerta dalla Società, che consente ai creatori di contenuti (“Creator”) di mettere a disposizione dei loro fan (“Fan”) il contenuto delle conversazioni intrattenute con terzi, ivi inclusi messaggi di testo, messaggi vocali, foto e video (“Conversazioni”), anche utilizzando un’apposita funzionalità di condivisione denominata snippet (“Snippet”)<br/>
                    <br/>
                    <b>2.	Finalità del trattamento</b><br/><br/>
                    I tuoi dati personali saranno trattati dal Titolare per le seguenti finalità, a seconda della tua interazione con il Sito Web, Sparkling o il Titolare stesso:<br/>
                    (i)	per permetterti di utilizzare il Sito Web;<br/>
                    (ii)	per fornire riscontro alle tue richieste pervenute mediante l’utilizzo dei dati di contatto presenti sul Sito Web;<br/>
                    (iii)	per consentirti di iscriverti a Sparkling e utilizzarne le relative funzionalità, che include anche l’invio, da parte della Società, di e-mail di servizio;<br/>
                    (iv)	per adempiere ad obblighi di legge;<br/>
                    (v)	per la raccolta di informazioni statistiche anonime sull’utilizzo del Sito Web e/o di Sparkling (es. il numero di visitatori) e verificarne il corretto funzionamento.<br/>
                    <br/>
                    <b>3.	Categorie e tipologie di dati personali oggetto del trattamento</b><br/><br/>
                    I dati personali oggetto del trattamento si riferiscono, a seconda dei casi, agli utenti che visitano il Sito nonché agli utenti che si iscrivono a Sparkling in qualità di Fan o Creator (congiuntamente, “Utenti”) e appartengono alle seguenti categorie:<br/>
                    a)	dati identificativi, di contatto e altri dati forniti volontariamente dagli Utenti: quali nome, cognome, data di nascita e indirizzo e-mail;<br/>
                    b)	dati di navigazione degli Utenti: si tratta di dati la cui trasmissione è implicita nell’uso dei protocolli di comunicazioni di internet e che i sistemi informatici e i software preposti al funzionamento del Sito Web e di Sparkling acquisiscono per garantirne il funzionamento. In questa categoria rientrano gli indirizzi IP o i nomi a dominio dei dispositivi utilizzati dagli utenti per accedere al Sito Web e/o a Sparkling, gli indirizzi URL delle risorse richieste, l’orario della richiesta, il metodo utilizzato per sottoporre la richiesta al server, ecc. I dati in questione vengono utilizzati per raccogliere informazioni statistiche anonime sull’utilizzo del Sito Web e/o di Sparkling (es. il numero di visitatori) e verificarne il corretto funzionamento. Per quanto attiene all’utilizzo dei cookie, si rimanda al par. 11 di seguito;<br/>
                    c)	ulteriori dati dei Creator: immagine profilo, partita IVA, IBAN, importi fatturati, volumi di vendita, username di altri social network;<br/>
                    d)	ulteriori dati dei Fan: dati di pagamento. Tuttavia, in proposito, si precisa che i dettagli relativi ai mezzi di pagamento utilizzati verranno trattati in maniera sicura dai fornitori dei servizi di pagamento. <br/>
                    Inoltre, si evidenzia che i Creator sono titolari dei dati personali di cui alle Conversazioni e che, al riguardo, la Società agisce quale responsabile del trattamento specificamente designato dai Creator. <br/>
                    <br/>
                    <b>4.	Basi giuridiche del trattamento</b><br/><br/>
                    In relazione alle finalità indicate al par. 2 che precede, la base giuridica del trattamento è costituita dalla necessità di dare esecuzione ad un contratto di cui l’interessato è parte o dall’adozione di misure precontrattuali adottate su richiesta dello stesso (art. 6, comma 1, lett. a), GDPR). Ciò, ad eccezione della: <br/>
                    •	finalità di elaborazione di statistiche aggregate e anonime, per cui si ritiene comunque sussistere il legittimo interesse della Società all’analisi e al miglioramento dei propri servizi, nonché a garantire il corretto funzionamento del Sito Web e di Sparkling sulla base di tali elaborazioni, ex art. 6, comma 1, lett. f), GDPR);<br/>
                    •	finalità di adempimento di obblighi di legge, come ad esempio nel caso della normativa fiscale applicabile al rapporto con i Creator o ai sensi di quanto previsto dall’art. 2220 del Codice Civile in materia di conservazione delle fatture, per cui la base giuridica è costituita dalla necessità di adempiere ad un obbligo di legge, ex art. 6, comma 1, lett. c), GDPR.<br/>
                    <br/>
                    <b>5.	Natura del conferimento dei dati</b><br/><br/>
                    Il mancato conferimento dei tuoi dati personali per le finalità su indicate impedirebbe alla Società di consentirti di utilizzare il Sito Web, di garantire il miglioramento e il corretto funzionamento dei servizi, di fornire riscontro alle tue richieste o di permetterti di iscriverti e utilizzare Sparkling. In tal senso, pertanto, il conferimento dei tuoi dati riveste carattere necessario. <br/>
                    <br/>
                    <b>6.	Destinatari dei dati personali</b><br/><br/>
                    Nei limiti pertinenti alle finalità sopra indicate, i tuoi dati personali potranno essere comunicati alle seguenti categorie di destinatari: <br/>
                    •	consulenti incaricati per l’installazione, la manutenzione, l’aggiornamento e, in generale, la gestione degli hardware e software della Società;<br/>
                    •	tutti i soggetti pubblici o privati rispetto ai quali la comunicazione debba essere effettuata per adempiere ad obblighi di legge;<br/>
                    •	ogni soggetto che abbia diritto di accedere ai dati in forza di provvedimenti normativi o ammnistrativi;<br/>
                    •	ulteriori soggetti il cui intervento sia necessario per il raggiungimento delle finalità richiamate al par. 2 di cui sopra, anche in qualità di responsabili del trattamento ex art. 28 GDPR.<br/>
                    <br/>
                    <b>7.	Trasferimento dei dati al di fuori dell’Unione europea o verso Organizzazioni internazionali</b><br/><br/>
                    I tuoi dati personali non verranno trasferiti dal Titolare in paesi al di fuori dell’Unione europea o verso Organizzazioni internazionali. Tuttavia, laddove il Titolare avesse necessità di procedere con tali trasferimenti, il trattamento avverrà secondo una delle modalità consentite ai sensi degli artt. 44-49 del GDPR e potrai chiedere copia delle garanzie appropriate per il trasferimento all’indirizzo e-mail di cui al par. 1 che precede.<br/>
                    <br/>
                    <b>8.	Modalità di trattamento dei dati</b><br/><br/>
                    I tuoi dati sono trattati in modo lecito e secondo correttezza, per il perseguimento delle finalità sopra indicate e nel rispetto dei principi fondamentali stabiliti dalla normativa applicabile. <br/>
                    Il trattamento dei dati personali può avvenire sia mediante strumenti manuali, che informatici e telematici, ma in ogni caso sotto il presidio di misure tecniche e organizzative idonee a garantirne la sicurezza e la riservatezza, soprattutto al fine di ridurre i rischi di distruzione, perdita o alterazione, illecite o accidentali, dei dati, di accesso non autorizzato, o di trattamento non consentito o non conforme alle finalità della raccolta. <br/>
                    <br/>
                    <b>9.	Conservazione dei dati personali</b> <br/><br/>
                    I tuoi dati personali saranno conservati per il tempo strettamente necessario al conseguimento delle finalità per cui sono raccolti, nel rispetto del principio di limitazione della conservazione di cui all’art. 5, par. 1, lett. e) del GDPR. In particolare, i tuoi dati personali verranno trattati dal Titolare per il tempo strettamente necessario a fornire riscontro alla Sua richiesta pervenuta mediante l’utilizzo delle informazioni di contatto presenti sul Sito Web e, in relazione ai dati di navigazione e ai cookie, ivi incluso per il miglioramento e il coretto funzionamento dei servizi, per un massimo di 24 mesi dalla raccolta. I dati raccolti in relazione ai servizi offerti da Sparkling saranno cancellati al termine del rapporto contrattuale con l’Utente e, in ogni caso, al massimo dopo 10 anni da tale momento per l’adempimento degli obblighi di conservazione previsti dalla legge (e.g., termine di conservazione di cui all’art. 2220 c.c.). Al termine dei predetti periodi, i tuoi dati personali saranno cancellati o, comunque, cesseranno di essere conservati in una forma che consenta la tua identificazione o identificabilità. <br/>
                    <br/>
                    <b>10.	I tuoi diritti </b><br/><br/>
                    Ai sensi del GDPR, hai diritto, laddove applicabile, di:<br/>
                    •	accedere ai tuoi dati personali, ottenendo evidenza delle finalità perseguite da parte del Titolare, delle categorie di dati coinvolti, dei destinatari a cui gli stessi possono essere comunicati, del periodo di conservazione applicabile, dell’esistenza di processi decisionali automatizzati;<br/>
                    •	ottenere senza ritardo la rettifica dei dati personali inesatti che ti riguardano;<br/>
                    •	ottenere, nei casi previsti dalla legge, la cancellazione dei tuoi dati o la limitazione del trattamento;<br/>
                    •	revocare in qualsiasi momento il consenso prestato, senza pregiudicare la liceità del trattamento effettuato prima della revoca;<br/>
                    •	richiedere, se previsto dalla legge, la portabilità dei dati che hai fornito al Titolare, ossia di riceverli in un formato strutturato, di uso comune e leggibile da dispositivo automatico, anche per trasmettere tali dati a un altro titolare, senza alcun impedimento da parte del Titolare stesso.<br/>
                    Hai, inoltre, il diritto di opporti, in qualsiasi momento, per motivi connessi alla tua situazione particolare, al trattamento dei tuoi dati personali basato sul legittimo interesse del Titolare. In caso di opposizione, il Titolare si asterrà dal trattare ulteriormente i tuoi dati personali salvo che dimostri l'esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sui tuoi interessi, diritti e libertà oppure per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria.<br/>
                    Per esercitare tali diritti, puoi contattare il Titolare ai recapiti indicati al par. 1 che precede. <br/>
                    Inoltre, qualora dovessi ritenere che il trattamento dei tuoi dati personali sia effettuato dal Titolare in violazione di quanto previsto dalla normativa in materia di protezione dei dati personali, hai il diritto di proporre reclamo al Garante per la protezione dei dati personali, come previsto dall'art. 77 GDPR.<br/>
                    <br/>
                    <b>11.	Cookie</b><br/><br/>
                    Il presente Sito Web nonché Sparkling utilizzano i c.d. cookie. <br/>
                    I cookie sono stringhe di testo che i siti web (c.d. publisher o “prima parte”) visitati dall’utente ovvero siti o web server diversi (c.d. “terze parti”) posizionano e archiviano all’interno di un dispositivo terminale dell’utente. <br/>
                    I cookie possono permanere nel sistema per la durata di una sessione (cioè fino a che non si chiude il browser utilizzato per la navigazione sul web) o per lunghi periodi e possono contenere un codice identificativo unico. <br/>
                    I dati personali raccolti attraverso i cookie (es. indirizzo IP dell’utente) sono trattati dal Titolare conformemente ai paragrafi che precedono, nel rispetto del GDPR e del Codice Privacy.<br/>
                    a.	Tipologie di cookie<br/>
                    In base alle caratteristiche e all'utilizzo dei cookie è possibile distinguere diverse categorie:<br/>
                    •	cookie tecnici: usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni specifiche sugli utenti che accedono ad una pagina web;<br/>
                    •	cookie analytics: utilizzati dai gestori dei siti web per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso, e quindi elaborare statistiche generali sul servizio e sul suo utilizzo.<br/>
                    •	cookie di profilazione: utilizzati per monitorare e profilare gli utenti durante la navigazione, studiare i loro movimenti e abitudini di consultazione del web o di consumo (cosa comprano, cosa leggono, ecc.), anche allo scopo di inviare pubblicità di servizi mirati e personalizzati (c.d. behavioural advertising).<br/>
                    b.	Cookie utilizzati dal Sito Web e Sparkling<br/>
                    Il presente Sito Web e Sparkling utilizzano unicamente cookie c.d. tecnici (es. per la memorizzazione dei dati di login dell’utente) e cookie analytics di natura tecnica, al fine di consentire al visitatore di utilizzare ogni funzionalità del Sito Web e di Sparkling e raccogliere dati aggregati su base statistica per migliorare le relative funzionalità e i servizi offerti. Il Titolare si serve, a tal proposito, delle funzionalità offerte da Google Analytics 4 con IP anonimizzato e ha ricevuto garanzie in merito all’assenza di trasferimenti di dati personali verso paesi al di fuori dell’UE. <br/>
                    c.	Impostazione delle preferenze<br/>
                    La maggior parte dei web browser è impostata per accettare i cookie in modo automatico. Tuttavia, l’utente può, in qualsiasi momento, impostare il proprio browser in modo da accettare tutti i cookie, solo alcuni, oppure rifiutarli, disabilitandone l’uso da parte dei siti web. Le preferenze relative ai cookie, in termini di autorizzazione, blocco o cancellazione (in tutto o in parte) devono essere impostate separatamente per ciascun browser, poiché ognuno di essi offre funzionalità e opzioni specifiche. Inoltre, è possibile, di norma, impostare le preferenze del browser in modo tale che l’utente venga informato ogni volta che un cookie viene memorizzato sul dispositivo utilizzato.  Al termine di ogni sessione di navigazione, infine, è possibile cancellare dal disco fisso del dispositivo i cookie raccolti. La disabilitazione totale o parziale dei cookie tecnici può compromettere, in tutto o in parte, l’utilizzo delle funzionalità dei siti, la visione dei contenuti e la fruibilità dei relativi servizi. <br/>
                    Le proprie preferenze in riferimento ai cookie andranno reimpostate nel caso in cui si utilizzino diversi dispositivi o browser per accedere al Sito Web e/o a Sparkling. I browser consentono, inoltre, di definire impostazioni diverse per i cookie di “prime parti” e per quelli di “terze parti”. Selezionando i collegamenti sottostanti è possibile ricevere istruzioni specifiche per alcuni dei principali browser web:<br/>
                    •	Microsoft Windows Explorer;<br/>
                    •	Microsof Edge;<br/>
                    •	Google Chrome;<br/>
                    •	Mozilla Firefox;<br/>
                    •	Opera (non disponibile in italiano);<br/>
                    •	Apple Safari.<br/>



                </p>

            </div>
            <div className='grid-cols-1'></div>

        </div>

    </div>
  )
}

export default Privacy