import React from 'react'
import Logo from '../Img/1.png'
import { Link, useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../Constants'
import { useState } from 'react'
import api from '../Api'

import { useUser } from '../contexts/UserContext';//

function Login() {

    const navigate = useNavigate()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const { setUser } = useUser()

    

    const handleForm = async (e) => {
        
        
        e.preventDefault()

        try{
            
            const res = await api.post("sparklingApi/token/", {username,  password})
            localStorage.setItem(ACCESS_TOKEN, res.data.access);
            localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
            //
            const userResponse = await api.get("/sparklingApi/user/getprofile/");
            //
            setUser(userResponse.data);
            
            navigate("/home/")
            


        } catch(error) {
            alert(error)
        } 
        
    }


    

        

  return (
    <>
    
    <div className='min-h-screen bg-white'>

        <div className='grid grid-cols-12 min-h-screen '>
            <div className='sm:col-span-4 col-span-1'></div>
            <div className='sm:col-span-4 col-span-10'>
            <Link to='/'>
                <img src={Logo} className='max-h-[50px] mt-10'></img>
            </Link>
                <div className='min-h-fit rounded-md shadow-2xl mt-6'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-1'></div>
                        <div className='col-span-10'>
                            <p className='font-poppinsBold text-xl sm:mt-10 mt-6 text-slate-700'>Accedi al tuo account</p>
                            <form onSubmit={handleForm}>
                                <p className='font-poppinsLight mt-10 font-bold text-sm text-slate-700'>Username</p>
                                <input onChange={(e) => setUsername(e.target.value)}  type='text' name='username' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>
                                
                                <p className='font-poppinsLight mt-3 font-bold text-sm text-slate-700'>Password</p>
                                <input onChange={(e) => setPassword(e.target.value)}  type='password' name='password' className='mt-3 px-2 py-3 w-full border border-slate-200 border-2 rounded-md focus:border-primary focus:outline-none focus:ring-0 ' required/>

                                <button type='submit' className='mt-8 w-full bg-primary text-white font-poppinsBold px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold'> Accedi > </button>
                            </form>
                            <div className='mt-10 sm:mb-4 mb-2 bg-slate-100 rounded-md min-h-[50px] '>
                                <p className=' text-center text-slate-500 font-poppinsLight py-1'>Non hai ancora un account Sparkling? <b className='text-primary'><Link to='/registrati'>Registrati</Link></b></p>
                                
                            </div>
                            <p className=' text-center text-slate-500 font-poppinsLight mt-4 mb-2'>Non ricordi la password? <b className='text-primary'><Link to='/request-reset-password'>Reset password</Link></b></p>
                            
                        </div>
                        <div className='col-span-1'></div>

                    </div>
                    

                </div>


            </div>
            <div className='sm:col-span-4 col-span-1'></div>
        </div>

    </div>
        
    
    
    
    
    
    </>
  )
}

export default Login