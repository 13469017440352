import React from 'react'

function Condizioni() {
  return (
    <div className='min-h-full'>
    <div className='grid col-span-12'>
        <div className='grid-cols-1'></div>
        <div className='grid-cols-10 '>
            <h1 className='font-poppinsLight text-lg text-slate-900 mt-6 text-center font-bold'>
                Condizioni generali di servizio
                <br/>
            </h1>
            <p className='font-poppinsLight text-base text-slate-700 mt-6 text-center'> 
                Aggiornata al 14.02.2025
            </p>
            <p className='font-poppinsLight text-sm text-slate-700 mt-6 p-4 leading-loose'> 

            <b>1.	Premessa</b><br/><br/>
            1.1.	Le presenti condizioni generali di servizio (“Condizioni Generali”) regolano l’iscrizione e l’utilizzo della piattaforma Sparkling (“Sparkling”), accessibile all’indirizzo web www.sparklingapp.it, da parte dei creatori di contenuti (“Creator”) e degli utenti interessati ad accedere a tali contenuti (“Fan” e, congiuntamente ai Creator, “Utenti”). <br/>
            1.2.	Sparkling è la piattaforma, offerta da Mearas S.r.l. Socio Unico, con sede in Via Passarella 4, 20122, Milano, C.F. e P. IVA 13996260967, che consente ai Creator di mettere a disposizione dei Fan il contenuto delle proprie conversazioni con terzi, ivi inclusi messaggi di testo, messaggi vocali, foto e video (“Conversazioni”). <br/>
            1.3.	L’utilizzo di Sparkling da parte degli Utenti è subordinato all’accettazione delle presenti Condizioni Generali. La Società si riserva di modificare le presenti Condizioni Generali secondo necessità (e.g., per aggiornarle in relazione a nuove funzionalità offerte), dandone debita comunicazione agli Utenti. Gli Utenti sono, in ogni caso, tenuti a verificare regolarmente il contenuto delle presenti Condizioni Generali. <br/>
            1.4.	Per contattare la Società per qualsiasi richiesta, ivi inclusi eventuali reclami o l’esercizio del diritto di recesso (ove applicabile), è possibile utilizzare il seguente indirizzo e-mail: info.sparklingapp@gmail.com. Inoltre, la Società mette a disposizione degli Utenti le risposte alle domande più frequenti (FAQ), accedendo a questo link.  <br/>
            <br/>
            <b>2.	Iscrizione a Sparkling</b><br/><br/>
            2.1.	Per utilizzare Sparkling, è richiesta la creazione di un account da parte degli Utenti. La registrazione comporta il trattamento, da parte della Società, dei dati personali forniti e gli Utenti si impegnano pertanto a fornire informazioni corrette e veritiere sul loro conto. A tal proposito, gli Utenti avranno la possibilità di consultare l’informativa sulla privacy disponibile qui nell’ambito del flusso di registrazione. <br/>
            2.2.	La creazione di un account è consentita unicamente agli Utenti di età maggiore di 18 anni. Al di sotto di tale età, la registrazione è consentita unicamente con l’autorizzazione dei titolari della responsabilità genitoriale e ferma restando la validità del consenso dei minori fra i 14 e 18 anni, laddove applicabile, alla luce della normativa in materia di protezione dei dati personali. La Società si riserva la facoltà di verificare in fase di registrazione l’esistenza di una prova idonea a dimostrare l’avvenuta autorizzazione del minore.  <br/>
            2.3.	La Società si riserva di condurre adeguate verifiche in ordine alle informazioni fornite e al fine di prevenire attività fraudolente, quale la sostituzione di persona, o comunque contrarie rispetto alle presenti Condizioni Generali. In tal caso, la Società rilascerà le credenziali di accesso agli Utenti solo in caso di esito positivo di tali verifiche.  <br/>
            <br/>
            <b>3.	Contenuto delle Conversazioni</b><br/><br/>
            3.1.	I Creator possono caricare le Conversazioni attraverso la funzione upload presente su Sparkling, seguendo le apposite istruzioni fornite nella rispettiva pagina personale dell’account e metterle così a diposizione dei Fan. Inoltre, i Creator hanno la possibilità di condividere con i Fan screenshot delle proprie Conversazioni attraverso la funzionalità snippet (“Snippet”). <br/>
            3.2.	Le Conversazioni e gli Snippet non devono avere contenuto contrario alla legge o alle presenti Condizioni Generali o violare diritti di terzi. <br/>
            3.3.	È in ogni caso fatto divieto ai Creator di caricare su Sparkling Conversazioni che abbiano ad oggetto contenuti per adulti sessualmente espliciti.<br/>
            <br/>
            <b>4.	Responsabilità</b> <br/><br/>
            4.1.	La Società nega qualsiasi responsabilità in relazione alle Conversazioni e agli Snippet, in linea con quanto previsto dalla normativa in materia di responsabilità dei fornitori di servizi della società dell’informazione e, in particolare, con il Regolamento (UE) 2022/2065 (c.d. Digital Services Act). I Creator sono gli unici responsabili delle Conversazioni e, pertanto:<br/>
            •	garantiscono di avere ottenuto dai terzi coinvolti nelle conversazioni ogni autorizzazione in materia di diritto d’autore o consenso ai sensi della normativa in materia di protezione dei dati personali, previa adeguata informativa. A tal fine, il Creator si impegna a regolare opportunamente il proprio rapporto contrattuale con i terzi in questione, restando inteso che la Società rimarrà del tutto estranea a tale rapporto e che i terzi non avranno nulla a che pretendere nei confronti della Società; e<br/>
            •	accettano di tenere indenne e manlevare la Società da qualsiasi perdita, danno, responsabilità, costo, onere e/o spesa (incluse, senza limitazione, spese legali ragionevolmente sostenute) che la Società possa subire o sostenere in conseguenza del caricamento di Conversazioni non lecite o contrarie alle presenti Condizioni Generali, ivi incluso con riferimento al mancato ottenimento delle autorizzazioni di cui al punto precedente. <br/>
            4.2.	Rispetto alla funzione upload delle Conversazioni e alla funzione Snippet, la Società agisce quale responsabile del trattamento dei dati personali dei terzi forniti dal Creator, ai sensi dell’art. 28 del Regolamento (UE) 2016/679. Accettando le presenti Condizioni Generali, il Creator designa la Società quale responsabile del trattamento mediante l’atto di designazione presente in appendice, che il Creator dichiara di aver letto e compreso. <br/>
            <br/>
            <b>5.	Conformità dei commenti </b><br/><br/>
            5.1.	Ogni commento inserito dagli Utenti all’interno di Sparkling dovrà essere conforme alla legge e alle presenti Condizioni Generali, secondo quanto già previsto per le Conversazioni alla clausola 3. Trova applicazione per i commenti anche la manleva in favore della Società di cui alla clausola 4.1. <br/>
            <br/>
            <b>6.	Misure di protezione </b><br/><br/>
            6.1.	Attraverso l’apposita funzione messa a diposizione all’interno di Sparkling, è possibile segnalare alla Società la presenza di contenuti che si presumono contrari alla legge o alle presenti Condizioni Generali. La Società si impegna a rivedere tali segnalazioni e ad adottare ogni misura adeguata per porre fine alla violazione, ivi inclusi, a titolo esemplificativo e non esaustivo, la rimozione della Conversazione e/o del commento del Fan o parte di essi, la sospensione dell’account dell’Utente o la sua cancellazione definitiva. <br/>
            6.2.	Nel caso in cui la Società dovesse ritenere una Conversazione o un commento dei Fan o dei Creator in contrasto con la legge o le presenti Condizioni Generali, l’Utente non avrà nulla a che pretendere nei confronti della Società, fatta salva la possibilità di conoscere le ragioni sottostanti alla misura adottata e ottenere una rivalutazione della medesima nel caso ricorrano ragioni comprovate per una revisione della decisione. <br/>
            <br/>
            <b>7.	Accesso alle Conversazioni da parte dei Fan</b><br/><br/>
            7.1.	I Fan possono accedere alle Conversazioni del Creator gratuitamente o mediante pagamento di un corrispettivo, a seconda delle restrizioni all’accesso selezionate dal Creator medesimo. L’acquisto consente al Fan di accedere alle Conversazioni e agli eventuali Snippet generati dal Creator per un periodo di tempo limitato. Attualmente, non sono previste formule di abbonamento. Il prezzo, inclusivo d’iva, e il limite temporale dell’accesso garantito al Fan sono specificati nella relativa pagina di acquisto. La Società si riserva di modificare, in qualsiasi momento, prima dell’acquisto, il prezzo e la durata dell’accesso. <br/>
            7.2.	Il pagamento del corrispettivo può essere eseguito attraverso carte di pagamento, Paypal o Stripe, seguendo le indicazioni fornite in fase di acquisto.<br/>
            7.3.	La Società accuserà ricevuta dell’ordine mediante invio di una specifica comunicazione all’indirizzo di posta elettronica fornito dal Fan in sede di registrazione. Tale comunicazione conterrà una copia delle presenti Condizioni Generali, unitamente all’indicazione del prezzo corrisposto per usufruire dell’accesso alle Conversazioni e/o agli Snippet del Creator. <br/>
            7.4.	I Fan riconoscono che l’accesso alle Conversazioni e/o agli Snippet comporta la decadenza dal diritto di recesso di 14 giorni riconosciuto dal codice del consumo (d.lgs. n. 206/2005), in virtù della deroga per i contenuti digitali di cui all’art. 59, comma 1, lett. o), del medesimo codice. In ogni caso, al momento dell’accesso, al Fan sarà richiesto di prestare il proprio consenso rispetto alla rinuncia a tale diritto.<br/>
            <br/>
            <b>8.	Corrispettivo per i Creator</b><br/><br/>
            8.1.	I Creator hanno diritto di ricevere dalla Società il 70% del corrispettivo versato da ciascun Fan per accedere alle loro Conversazioni e/o pagine Snippet, al netto delle commissioni, fisse e variabili, e oneri richiesti dal fornitore di servizi di pagamento. Tale percentuale non subirà variazioni in rapporto al numero complessivo di Fan attivi su Sparkling in relazione ad uno specifico Creator.<br/>
            8.2.	La Società si impegna a pagare il corrispettivo al Creator entro 60 giorni dall’emissione, da parte del Creator, della relativa fattura trasmessa alla Società alla fine di ogni mese, a condizione che la stessa sia corretta, non contestata e contenga tutti i dettagli richiesti dalla normativa.     <br/>
            8.3.	Il Creator potrà visualizzare un riepilogo delle vendite realizzate all’interno della propria pagina personale dell’account. <br/>
            8.4.	Il Creator garantisce l’osservanza di ogni previsione in materia fiscale e/o tributaria applicabile e riconosce di essere l’unico responsabile del pagamento di tasse e/o oneri dovuti allo Stato. <br/>
            <br/>
            <b>9.	Esclusiva</b><br/><br/>
            9.1.	Il Creator si impegna, per la durata del rapporto contrattuale con la Società, coincidente con il periodo indicato alla successiva clausola 11.1, a non fornire, distribuire, vendere, caricare o rendere in altro modo disponibili le Conversazioni e/o Snippet caricati su Sparkling a nessun’altra piattaforma e/o persona fisica o giuridica diversa dalla Società, senza la previa autorizzazione scritta da parte di quest’ultima.<br/>
            9.2.	In caso di violazione della presente clausola di esclusiva, il Creator riconosce che la Società avrà diritto a richiedere il risarcimento dei danni subiti, oltre a qualsiasi altro rimedio legale disponibile, inclusa la risoluzione del rapporto contrattuale.<br/>
            <br/>
            <b>10.	Indisponibilità di Sparkling</b><br/><br/>
            10.1.	Di tanto in tanto, Sparkling potrebbe essere temporaneamente non disponibile a causa di interventi di manutenzione programmata o straordinaria, aggiornamenti tecnici, o altre operazioni necessarie per garantire il corretto funzionamento e la sicurezza dei servizi forniti, ivi incluso con riferimento ad eventuali picchi di traffico sul server e/o guasti hardware e/o simili. La Società si riserva il diritto di sospendere l’accesso a Sparkling, in tutto o in parte, senza preavviso nel caso di interventi straordinari, per il tempo necessario a completare tali interventi.<br/>
            10.2.	La Società non sarà in alcun modo responsabile per eventuali danni, perdite di dati, interruzioni del servizio, o qualsiasi altra conseguenza derivante dall’indisponibilità di Sparkling durante i periodi di manutenzione o indisponibilità. Gli Utenti riconoscono e accettano che tali interventi sono essenziali per la manutenzione, la sicurezza e il miglioramento dei servizi offerti.<br/>
            10.3.	Analogamente, la Società non potrà essere ritenuta responsabile nel caso in cui, per ragioni indipendenti dalla sua volontà o colpa (e.g., attacchi informatici esterni), alcune informazioni necessarie all’adempimento di quanto previsto alle clausole 8.1 e 8.2 siano momentaneamente o definitivamente indisponibili all’interno dei propri database, rendendo impossibile l’adempimento. In tal caso, la Società si impegna comunque a compiere ogni sforzo ragionevole per l’individuazione della soluzione di maggior tutela in relazione agli interessi coinvolti. <br/>
            <br/>
            <b>11.	Cancellazione dell’account</b><br/><br/>
            11.1.	Le presenti Condizioni Generali regolano il rapporto fra la Società e gli Utenti sino a quando, per qualsiasi ragione, l’account non venga cancellato. <br/>
            11.2.	Oltre a quanto già indicato alla precedente clausola 6, la Società si riserva, previo preavviso entro un termine congruo, la facoltà di cancellare l’account di un Utente in caso di inattività perdurante per almeno 6 mesi. <br/>
            11.3.	Gli Utenti possono, inoltre, cancellare l’account creato su Sparkling in qualsiasi momento. In tal caso, la Società provvederà alla cancellazione:<br/>
            •	nel caso dei Fan, in un termine ragionevole e comunque non prima della scadenza dell’ultimo periodo di accesso acquistato in relazione alle Conversazioni e/o pagina Snippet di un Creator; e<br/>
            •	nel caso dei Creator, in un termine ragionevole e comunque non prima della scadenza dell’ultimo periodo di accesso acquistato da un Fan in relazione alle Conversazioni e/o pagina Snippet del Creator medesimo.<br/>
            11.4.	A seguito della cancellazione, la Società tratterà i dati personali forniti unicamente secondo quanto previsto nell’informativa sulla privacy disponibile qui.<br/>
            <br/>
            <b>12.	Proprietà intellettuale</b><br/><br/>
            12.1.	Tutti i contenuti presenti su Sparkling, diversi dalle Conversazioni e dagli Snippet, inclusi i testi, la grafica, i loghi, le icone, le immagini e il software, sono di proprietà esclusiva della Società o, a seconda dei casi, dei suoi fornitori di contenuti e sono protetti dalle leggi internazionali sul diritto d'autore, sui marchi e su altri diritti di proprietà intellettuale.<br/>
            12.2.	La Società concede agli Utenti una licenza limitata, non esclusiva, non trasferibile e revocabile per accedere e, nel caso dei Fan, fare uso personale e non commerciale di Sparkling. A titolo esemplificativo e non esaustivo, questa licenza non include alcun diritto di:<br/>
            (a)	Rivenderne i contenuti;<br/>
            (b)	Fare un uso derivato di Sparkling o dei suoi contenuti;<br/>
            (c)	Scaricare o copiare informazioni relative all’account a beneficio di terzi;<br/>
            (d)	Utilizzare strumenti di data mining o comunque automatizzati per la raccolta ed estrazione di dati;<br/>
            (e)	Effettuare attività di reverse engineering, decompilare o disassemblare qualsiasi parte di Sparkling o dei suoi contenuti.<br/>
            12.3.	Qualsiasi uso non autorizzato comporta automaticamente il venire meno delle licenze concesse dalla Società, restando salvo il diritto della stessa di agire in giudizio a tutela dei propri diritti.<br/>
            <br/>
            <b>13.	Legge e foro competente</b><br/><br/>
            13.1.	Il contratto tra l’Utente e la Società s’intende concluso in Italia ed è, pertanto, regolato dalla legge italiana. Per la risoluzione di eventuali controversie relative all’interpretazione, esecuzione o risoluzione delle presenti Condizioni Generali, se l’Utente è qualificabile come “consumatore” ai sensi del codice del consumo (d.lgs. n. 206/2005), sarà competente in via esclusiva il foro del comune di residenza o di domicilio dell’Utente (e.g., nel caso dei Fan); in tutti gli altri casi, la competenza territoriale è esclusivamente quella del Foro di Milano.<br/>
            13.2.	Ai sensi dell’art. 14 del Regolamento 524/2013 si informa l’Utente che si qualifichi come “consumatore” che in caso di controversia potrà presentare un reclamo mediante la piattaforma ODR dell’Unione europea raggiungibile al seguente link http://ec.europa.eu/consumers/odr/. La piattaforma ODR costituisce un punto di accesso per gli Utenti consumatori che desiderano risolvere in ambito extragiudiziale le controversie derivanti dai contratti online. A tal fine si comunica che l’indirizzo di posta elettronica della Società è info.sparklingapp@gmail.com mentre l’indirizzo PEC è mearas@legalmail.it.  <br/>
            <br/>
            <b>14.	Accettazione espressa</b><br/><br/>
            14.1.	I Creator dichiarano di accettare espressamente, apponendo una specifica spunta nella relativa casella, le seguenti clausole delle presenti Condizioni Generali: 1.3 (modifica delle Condizioni Generali), 4 (Responsabilità), 5 (Conformità dei commenti), 6 (Misure di protezione), 9 (Esclusiva), 10 (Indisponibilità di Sparkling) e 13.1 (Foro competente). I Creator accettano espressamente, inoltre, il contenuto dell’atto di designazione della Società quale responsabile del trattamento disponibile in appendice, che dichiarano pertanto di aver letto e compreso per intero.<br/>
            14.2.	I Fan dichiarano di accettare espressamente, apponendo una specifica spunta nella relativa casella, le seguenti clausole delle presenti Condizioni Generali: 1.3 (modifica delle Condizioni Generali), 5 (Conformità dei commenti), 6 (Misure di protezione), 7.4 (Recesso), 10 (Indisponibilità di Sparkling).<br/>
             <br/>
            Appendice – Designazione della Società quale responsabile del trattamento ex art. 28 Regolamento (UE) 2016/679 (“GDPR”)<br/>
            <br/>
            1.	Designazione.<br/> Il Creator, in qualità di titolare del trattamento dei dati personali dei terzi coinvolti nelle Conversazioni (anche “Titolare”), attraverso il presente atto di designazione, nomina responsabile del trattamento dei dati la Società (anche “Responsabile”), riconoscendo che essa possiede l’esperienza, la capacità, l’affidabilità e fornisce idonee garanzie del pieno rispetto delle disposizioni vigenti in materia di trattamento dati, ivi compreso il profilo della sicurezza in relazione alle finalità e alle modalità delle operazioni di trattamento nonché alle garanzie di tutela dei diritti dell’interessato (“Atto di Designazione”).<br/>
            2.	Ambito del trattamento.<br/> Il Responsabile è autorizzato dal Titolare al trattamento dei dati personali qui di seguito puntualmente individuati per natura e finalità, tipologia, e per categorie di interessati:<br/>
            •	Natura del trattamento: conservazione e messa a disposizione delle Conversazioni su Sparkling, previo caricamento, mediante la funzione, upload, da parte del Creator;<br/>
            •	Finalità: messa a disposizione ai Fan delle Conversazioni;<br/>
            •	Tipologia dei dati: comuni, quali messaggi testuali, messaggi audio, video e foto. Tali contenuti, tuttavia, potrebbero avere ad oggetto anche dati personali appartenenti a categorie particolari o relativi a condanne penali e reati (e.g., dati relativi alla salute o a procedimenti penali), nel rispetto di quanto previsto dagli artt. 9 e 10 GDPR in relazione alle condizioni di liceità del trattamento. <br/>
            •	Categorie di interessati: terzi coinvolti nelle Conversazioni con il Creator. <br/>
            3.	Durata.<br/> Il presente Atto di Designazione ha la medesima durata ed efficacia del contratto intercorrente tra Società e Creator disciplinato dalle Condizioni Generali (v. la relativa clausola 11). Resta inteso che, interrotto il rapporto contrattuale per qualsiasi ragione, il Responsabile dovrà, su istruzione del Titolare, restituire o cancellare i dati personali, e le relative copie esistenti, salvo nel caso in cui la conservazione sia necessaria per rispettare un obbligo di legge. <br/>
            4.	Obblighi del Titolare.<br/> Il Titolare si impegna a condividere con il Responsabile unicamente i dati personali di terzi per cui abbia ottenuto, laddove richiesto, i necessari consensi in base alla normativa in materia di protezione dei dati personali e, in ogni caso, rispetto a terzi che abbia adeguatamente informato ai sensi degli artt. 12 e ss. del GDPR. In caso di violazione, da parte del Titolare, di tale ultima normativa, questi risponderà di ogni danno causato nei confronti del Responsabile e si impegna sin da ora, a tal fine, a manlevare e tenere indenne il Responsabile da qualsiasi onere, spesa, danno, sanzione o altra passività subita dal Responsabile come conseguenza diretta o indiretta del trattamento di dati personali svolto dal Titolare in contrasto con qualsivoglia legge applicabile. <br/>
            5.	Obblighi del Responsabile.<br/> Il presente atto obbliga il Responsabile ad osservare gli obblighi di cui all’Allegato A. <br/>
            <br/>
            ALLEGATO A – Obblighi del Responsabile<br/>
            <br/>
            A.	Rispetto delle istruzioni impartite: il Responsabile  deve trattare i dati personali soltanto su istruzione documentata  del Titolare, anche in caso di trasferimento di dati personali verso un paese terzo o un’organizzazione internazionale, salvo che lo richieda il diritto dell’UE o nazionale; in tal caso, il Responsabile deve informare il Titolare circa tale obbligo giuridico prima del trattamento, a meno che la legge vieti tale informazione per rilevanti motivi di interesse pubblico;<br/>
            B.	Riservatezza: il Responsabile assicura che le persone autorizzate al trattamento  dei dati personali siano vincolate da adeguati obblighi di riservatezza rispetto alle operazioni di trattamento effettuate per conto del Titolare.<br/>
            C.	Informazioni sulla conformità: dietro richiesta e in un termine ragionevole, il Responsabile mette a disposizione del Titolare le informazioni necessarie a dimostrare il rispetto degli obblighi di cui all’art. 28 GDPR e consente e contribuisce, con spese a carico del Titolare, alle attività di revisione, comprese le ispezioni -  previo congruo preavviso, comunque non inferiore ad un mese – realizzati dal Titolare o da un altro soggetto da questi incaricato. <br/>
            D.	Richieste degli interessati: il Responsabile fornisce assistenza al Titolare, nella misura possibile, per soddisfare le richieste degli interessati, tenendo conto natura del trattamento. In particolare, entro un termine ragionevole, il Responsabile trasmetterà al Titolare ogni richiesta ricevuta in tal senso, nonché metterà a disposizione del Titolare, su sua richiesta, le informazioni in suo possesso che siano di supporto per il riscontro della richiesta ricevuta dal Titolare.<br/>
            E.	Violazione di dati: il Responsabile informerà il Titolare, senza ingiustificato ritardo da quando ne sia venuto a conoscenza, di eventuali violazioni di dati personali. Inoltre, fornirà assistenza al Titolare, tenendo conto della natura del trattamento e delle informazioni a sua disposizione, per garantire l’osservanza degli obblighi di notifica agli interessati medesimi e all’autorità di controllo.<br/>
            F.	Valutazioni di impatto sulla protezione dei dati: tenendo conto della natura del trattamento e delle informazioni a sua disposizione, il Titolare supporterà il Titolare, dietro sua ragionevole richiesta, nell’eventuale predisposizione della valutazione d’impatto sulla protezione dei dati e/o consultazione preventiva con l’autorità di controllo. <br/>
            G.	Sub-responsabili: il Titolare fornisce un’autorizzazione generale al Responsabile affinché si avvalga di eventuali sub-responsabili per lo svolgimento delle operazioni di trattamento sopra descritte. Il Responsabile informerà il Titolare di ogni modifica e/o sostituzione dei sub-responsabili (e.g., mediante pubblicazione dell’elenco dei sub-responsabili in una apposita sezione di Sparkling) entro un termine ragionevole, dando così al Titolare la possibilità di opporsi in caso di adeguata e comprovata motivazione. In ogni caso, il Titolare si impegna a sottoscrivere con ciascun sub-responsabile un contratto avente ad oggetto gli obblighi contenuti nel presente Atto di Designazione, con garanzia di osservanza del GDPR. Resta fermo che, nei confronti del Titolare, il Responsabile conserverà la responsabilità per gli inadempimenti dei sub-responsabili. <br/>
            H.	Sicurezza. Il Responsabile mette in atto ogni misura tecnica e organizzativa richiesta ai sensi dell’art. 32 GDPR, tenendo conto dello stato dell'arte e dei costi di attuazione, nonché della natura, dell'oggetto, del contesto e delle finalità del trattamento, come anche del rischio di varia probabilità e gravità per i diritti e le libertà delle persone fisiche.<br/>
            <br/>





            </p>

        </div>
        <div className='grid-cols-1'></div>

    </div>

</div>
  )
}

export default Condizioni